<div class="card">
  <div class="card-body p-4">
    <h2 class="card-title mb-5"[innerHTML]="step?.title || '' | translate ">{{ step?.title || '' | translate }}</h2>
    
    <div class="justify-content-center d-flex mb-5" *ngIf="!!step?.imageUrl">
      <img class="mw-100 mh-100" src="{{ step!.imageUrl! }}" />
    </div>

    <p class="question-description" *ngIf="!!step?.message">
      {{ step!.message! | translate }}
    </p>

    <div class="justify-content-center d-flex mb-5">
      <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
    </div>

    <div class="d-flex gap-2 mb-5">
      <button
        *ngIf="selectedIndex > 0"
        class="btn btn-outline-primary btn-lg flex-fill"
        (click)="handlePrevious()"
      >
        {{ step?.buttonPreviousStepTitle || 'buttons.back' | translate }}
      </button>
      <button
        class="btn btn-primary btn-lg flex-fill"
        [disabled]="!ignoreValidation && selectedFormGroup?.invalid"
        (click)="handleNext()"
      >
        <ng-container *ngIf="!isLastStep">
          {{ step?.buttonNextStepTitle || 'buttons.next' | translate }}
        </ng-container>
        <ng-container *ngIf="isLastStep">
          {{ step?.buttonNextStepTitle || 'buttons.submit' | translate }}
        </ng-container>
      </button>
    </div>

    <ng-container *ngIf="step?.skipStepLinkText">
      <div class="d-flex justify-content-center">
        <button class="btn btn-link" (click)="handleSkip()">
          {{ step?.skipStepLinkText || 'buttons.skip' | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
