/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { AuthProfileActions, BaseAppState, CancerTherapyType, CancerType, ObservableComponent, UserRole, WeightHistoryApiActions, getLoggedInUser, getUserLanguage, goToSurvey, isAnonymousUser, updateProfile, updateProfileSuccess } from 'ngx-esprio-shared';
import { combineLatest } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user-core-data-page',
  templateUrl: './edit-user-core-data-page.component.html',
})
export class EditUserCoreDataPageComponent extends ObservableComponent {
  public readonly asyncProcessKeys = [updateProfile.type];

  public readonly form: FormGroup = new FormGroup({
    monitoringUser: new FormGroup({
      code: new FormControl(null, [
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
      ]),
    }),
    personalData: new FormGroup({
      gender: new FormControl(null, [Validators.required]),
      birthday: new FormControl(null, Validators.required),
      bodySizeInCm: new FormControl(null, [Validators.required, Validators.min(1)]),
      initialWeightInKg: new FormControl(null, [
        Validators.required,
        Validators.min(10),
        Validators.max(250),
      ]),
      weightSixMonthsAgoInKg: new FormControl(null, [
        Validators.required,
        Validators.min(10),
        Validators.max(250),
      ]),
    }),
    generalWellBeing: new FormGroup({
      generalWellBeing: new FormControl(null, [Validators.required]),
    }),
    cancerTypes: new FormGroup({
      cancerTypes: new FormControl([], [Validators.required]),
    }),
    therapyTypes: new FormGroup({
      therapyTypes: new FormControl([], [Validators.required]),
    }),
  });

  public loggedInUser$ = this.store.select(getLoggedInUser);
  private isAnonymous$ = this.store.select(isAnonymousUser);
  private language$ = this.store.select(getUserLanguage);

  public steps$ = this.isAnonymous$.pipe(
    map((anonymous) => [
      {
        title: anonymous ? 'personal_data.title_anonymous' : 'personal_data.title',
        buttonNextStepTitle: anonymous ? 'personal_data.next_anonymous' : 'personal_data.next',
        imageUrl: 'assets/images/PersonalData.svg',
        message: anonymous ? 'personal_data.message_anonymous' : 'personal_data.message',
      },
      {
        title: 'personal_data.personal_data',
        formGroupName: 'personalData',
      },
      {
        title: 'personal_data.general_well_being.title',
        formGroupName: 'generalWellBeing',
        options: [
          { titleKey: 'general.well_being_gradient.0', value: 1 },
          { titleKey: 'general.well_being_gradient.1', value: 2 },
          { titleKey: 'general.well_being_gradient.2', value: 3 },
          { titleKey: 'general.well_being_gradient.3', value: 4 },
          { titleKey: 'general.well_being_gradient.4', value: 5 },
        ],
      },
      {
        title: 'personal_data.cancer_type.title',
        message: 'personal_data.cancer_type.message',
        formGroupName: 'cancerTypes',
        options: [
          {
            titleKey: 'personal_data.cancer_type.options.0',
            value: [CancerType.Blood, CancerType.Lymph],
          },
          {
            titleKey: 'personal_data.cancer_type.options.1',
            value: [
              CancerType.Stomach,
              CancerType.Esphagus,
              CancerType.Liver,
              CancerType.Pancreas,
              CancerType.BileDucts,
            ],
          },
          { titleKey: 'personal_data.cancer_type.options.2', value: [CancerType.Bowel] },
          { titleKey: 'personal_data.cancer_type.options.3', value: [CancerType.Lung] },
          { titleKey: 'personal_data.cancer_type.options.4', value: [CancerType.Breast] },
          {
            titleKey: 'personal_data.cancer_type.options.5',
            value: [CancerType.Uterus, CancerType.Ovaries],
          },
          {
            titleKey: 'personal_data.cancer_type.options.6',
            value: [CancerType.Kidney, CancerType.Bladder],
          },
          { titleKey: 'personal_data.cancer_type.options.7', value: [CancerType.Prostate] },
          { titleKey: 'personal_data.cancer_type.options.8', value: [CancerType.Other] },
          { titleKey: 'personal_data.cancer_type.options.9', value: [CancerType.Unknown] },
        ],
        multiSelect: true,
      },
      {
        title: 'personal_data.therapy_type.title',
        message: 'personal_data.therapy_type.message',
        formGroupName: 'therapyTypes',
        options: [
          {
            titleKey: 'personal_data.therapy_type.options.0',
            value: [CancerTherapyType.Immunobody, CancerTherapyType.Antibody],
          },
          {
            titleKey: 'personal_data.therapy_type.options.1',
            value: [CancerTherapyType.Radiation],
          },
          { titleKey: 'personal_data.therapy_type.options.2', value: [CancerTherapyType.Hormone] },
          { titleKey: 'personal_data.therapy_type.options.3', value: [CancerTherapyType.Chemo] },
          { titleKey: 'personal_data.therapy_type.options.4', value: [CancerTherapyType.Surgery] },
          { titleKey: 'personal_data.therapy_type.options.5', value: [CancerTherapyType.Other] },
          { titleKey: 'personal_data.therapy_type.options.6', value: [CancerTherapyType.Unknown] },
          { titleKey: 'personal_data.therapy_type.options.7', value: [CancerTherapyType.Complete] },
          { titleKey: 'personal_data.therapy_type.options.8', value: [CancerTherapyType.None] },
        ],
        multiSelect: true,
      },
    ])
  );

  public constructor(private readonly store: Store<BaseAppState>) {
    super();
  }

  public handleStepChange(): void {
    window.scroll(0, 0);
  }

  public handleFinishWizard(): void {
    combineLatest([this.loggedInUser$, this.isAnonymous$, this.language$])
      .pipe(
        take(1),
        tap(([user, isAnonymous, language]) => {
          if (!isAnonymous) {
            this.store.dispatch(
              updateProfile({
                payload: {
                  // account data
                  firstName: user!.firstName,
                  lastName: user!.lastName,
                  // personal data
                  birthday: moment
                    .utc(this.form.get('personalData.birthday')!.value)
                    .startOf('day')
                    .toISOString(),
                  bodySizeInCm: this.form.get('personalData.bodySizeInCm')!.value,
                  gender: this.form.get('personalData.gender')!.value,
                  initialWeightInKg: this.form.get('personalData.initialWeightInKg')!.value,
                  weightSixMonthsAgoInKg: this.form.get('personalData.weightSixMonthsAgoInKg')!
                    .value,
                  // diagnosis data
                  generalWellBeing: this.form.get('generalWellBeing.generalWellBeing')!.value,
                  cancerTypes: this.form.get('cancerTypes.cancerTypes')!.value,
                  therapyTypes: this.form.get('therapyTypes.therapyTypes')!.value,
                },
                redirect: false,
              })
            );

            this.store.dispatch(WeightHistoryApiActions.setLastWeightCheck({ userId: user!.id }));
            this.store.dispatch(AuthProfileActions.hasMonitoringRedirectIfNeeded());
          } else {
            this.store.dispatch(
              updateProfileSuccess({
                response: {
                  user: {
                    id: '',
                    allowedMonitoringUserIds: [],
                    email: '',
                    firstName: '',
                    language: language,
                    lastName: '',
                    role: UserRole.User,
                    coreData: {
                      birthday: moment
                        .utc(this.form.get('personalData.birthday')!.value)
                        .startOf('day')
                        .toISOString(),
                      bodySizeInCm: this.form.get('personalData.bodySizeInCm')!.value,
                      gender: this.form.get('personalData.gender')!.value,
                      initialWeightInKg: this.form.get('personalData.initialWeightInKg')!.value,
                      weightSixMonthsAgoInKg: this.form.get('personalData.weightSixMonthsAgoInKg')!
                        .value,
                      generalWellBeing: this.form.get('generalWellBeing.generalWellBeing')!.value,
                      cancerTypes: this.form.get('cancerTypes.cancerTypes')!.value,
                      therapyTypes: this.form.get('therapyTypes.therapyTypes')!.value,
                    },
                  },
                },
                redirect: false,
              })
            );
            this.store.dispatch(goToSurvey({ submissionId: '', anonymous: true }));
          }
        })
      )
      .subscribe();
  }
}
