<!-- gender selection -->
<div class="mb-3">
  <label for="gender" class="form-label">{{ 'personal_data.sex' | translate }}</label>
  <select class="form-select form-select-lg" id="gender" [formControl]="getFormControl('gender')">
    <option hidden disabled selected [ngValue]="null">
      {{ 'personal_data.choose_sex' | translate }}
    </option>
    <option [ngValue]="0">{{ 'personal_data.man' | translate }}</option>
    <option [ngValue]="1">{{ 'personal_data.woman' | translate }}</option>
    <option [ngValue]="2">{{ 'personal_data.diverse' | translate }}</option>
  </select>

  <div class="invalid-feedback">
    <div *ngIf="getFormControl('gender').hasError('required')">
      {{ 'personal_data.error_required' | translate }}
    </div>
  </div>
</div>

<!-- date of birth -->
<div class="mb-3">
  <label for="birthday" class="form-label">
    {{ 'personal_data.date_of_birth' | translate }}
  </label>
  <input
    type="text"
    class="form-control form-control-lg"
    id="birthday"
    placeholder="{{ 'personal_data.choose_date' | translate }}"
    autocomplete="off"
    bsDatepicker
    [formControl]="getFormControl('birthday')"
    [bsConfig]="datepickerConfig"
  />

  <div class="invalid-feedback">
    <div *ngIf="getFormControl('birthday').hasError('required')">
      {{ 'personal_data.error_required' | translate }}
    </div>
  </div>
</div>

<!-- body size -->
<div class="mb-3">
  <label for="bodySizeInCm" class="form-label">{{ 'personal_data.height' | translate }}</label>
  <input
    type="number"
    inputmode="decimal"
    class="form-control form-control-lg"
    id="bodySizeInCm"
    placeholder="{{ 'personal_data.choose_height' | translate }}"
    [formControl]="getFormControl('bodySizeInCm')"
  />

  <div class="invalid-feedback">
    <div *ngIf="getFormControl('bodySizeInCm').hasError('required')">
      {{ 'personal_data.error_required' | translate }}
    </div>
  </div>
</div>

<!-- current weight -->
<div class="mb-3">
  <label for="initialWeightInKg" class="form-label">{{
    'personal_data.initial_weight' | translate
  }}</label>
  <input
    type="number"
    inputmode="decimal"
    class="form-control form-control-lg"
    id="initialWeightInKg"
    placeholder="{{ 'personal_data.choose_initial_weight' | translate }}"
    [formControl]="getFormControl('initialWeightInKg')"
  />

  <div class="invalid-feedback">
    <div *ngIf="getFormControl('initialWeightInKg').hasError('required')">
      {{ 'personal_data.error_required' | translate }}
    </div>
    <div
      *ngIf="
        getFormControl('initialWeightInKg').hasError('min') ||
        getFormControl('initialWeightInKg').hasError('max')
      "
    >
      {{ 'personal_data.error_invalid_weight' | translate }}
    </div>
  </div>
</div>

<!-- weight 6 months ago -->
<div class="mb-3">
  <label for="weightSixMonthsAgoInKg" class="form-label">{{
    'personal_data.weight_six_months_ago' | translate
  }}</label>
  <input
    type="number"
    inputmode="decimal"
    class="form-control form-control-lg"
    id="weightSixMonthsAgoInKg"
    placeholder="{{ 'personal_data.choose_previous_weight' | translate }}"
    [formControl]="getFormControl('weightSixMonthsAgoInKg')"
  />

  <div class="invalid-feedback">
    <div *ngIf="getFormControl('weightSixMonthsAgoInKg').hasError('required')">
      {{ 'personal_data.error_required' | translate }}
    </div>
    <div
      *ngIf="
        getFormControl('weightSixMonthsAgoInKg').hasError('min') ||
        getFormControl('weightSixMonthsAgoInKg').hasError('max')
      "
    >
      {{ 'personal_data.error_invalid_weight' | translate }}
    </div>
  </div>
</div>
