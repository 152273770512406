import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAppState, Survey, SurveyApiActions, SurveySelectors } from 'ngx-esprio-shared';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-on-demand-survey',
  templateUrl: './on-demand-survey.component.html',
  styleUrls: ['./on-demand-survey.component.scss'],
})
export class OnDemandSurveyComponent implements OnInit {
  private surveys$ = this.store.select(SurveySelectors.getSurveys);

  constructor(private readonly store: Store<BaseAppState>) {}

  ngOnInit(): void {
    this.store.dispatch(SurveyApiActions.getSurveys());
  }

  public async handleStartSurveyClick(): Promise<void> {
    const surveys = await firstValueFrom(this.surveys$);

    const survey = surveys.find((survey: Survey) => survey.familyName === 'on-demand-baselineWebk');

    if (survey) {
      this.store.dispatch(
        SurveyApiActions.createSubmission({
          scheduleId: survey.scheduleId,
          surveyId: survey.id,
        })
      );
    }
  }
}
