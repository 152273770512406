import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  MonitoringUserApiActions,
  ObservableComponent,
  RelativeAccountActions,
  getIsRelativeUserAccount,
  getUserLanguageOrUndefined,
  isLoggedIn,
} from 'ngx-esprio-shared';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, take, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends ObservableComponent implements OnInit {
  public readonly asyncProcessKeys = [MonitoringUserApiActions.getMonitoringUsers.type];

  private loggedIn$ = this.store.select(isLoggedIn);
  private selectedLanguage$ = this.store.select(getUserLanguageOrUndefined);

  constructor(private translate: TranslateService, private store: Store) {
    super();
  }

  ngOnInit() {
    this.setInitialLanguage();
    this.initUserRelatedResources();
  }

  private setInitialLanguage() {
    combineLatest([this.loggedIn$, this.selectedLanguage$])
      .pipe(
        take(1),
        tap(([loggedIn, selectedLanguage]) => {
          if (!loggedIn) {
            let langToUse = 'de';
            //if(env.useBrowserLangIfAvailable){
            const browserLang = selectedLanguage ?? navigator.language.split('-')[0];
            const supportedLangs = this.translate.getLangs();

            langToUse = supportedLangs.includes(browserLang) ? browserLang : langToUse;
          //}
            this.translate.setDefaultLang(langToUse);
            this.translate.use(langToUse);
          }
        })
      )
      .subscribe();
  }

  private initUserRelatedResources() {
    this.store
      .select(isLoggedIn)
      .pipe(
        distinctUntilChanged(),
        filter((isLoggedIn) => isLoggedIn),
        withLatestFrom(this.store.select(getIsRelativeUserAccount)),
        tap(([_isLoggedIn, isRelativeUserAccount]) => {
          if (isRelativeUserAccount) {
            this.store.dispatch(RelativeAccountActions.getRelativePatients());
          } else {
            this.store.dispatch(MonitoringUserApiActions.getMonitoringUsers());
            this.store.dispatch(MonitoringUserApiActions.getPendingMonitoringUsers());
            this.store.dispatch(MonitoringUserApiActions.getMonitoringUsersInvitations());
          }
        })
      )
      .subscribe();
  }
}
